<script setup lang="ts">
import * as THREE from 'three'
import { FilmPass } from 'three/addons/postprocessing/FilmPass.js'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'

import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import { onMounted, ref } from 'vue'

// Define a ref to hold the container element
const threeContainer = ref<HTMLDivElement | null>(null)
const gap = 5
const numberOfPlanes = 20

let composer: EffectComposer | undefined

const pause = ref(true)

// Set up the scene
const scene = new THREE.Scene()

// Mirror Planes
const planes: any[] = []

function initThreeJS() {
  if (!threeContainer.value)
    return

  scene.background = new THREE.Color(0x000000)

  // Set up the camera with a wider FOV for a bending effect
  const camera = new THREE.PerspectiveCamera(75, threeContainer.value.clientWidth / threeContainer.value.clientHeight, 0.1, 1000)
  camera.position.z = -60
  camera.position.x = 50
  camera.position.y = -10
  // Rotate the camera
  camera.rotation.x = THREE.MathUtils.degToRad(0)
  camera.rotation.y = THREE.MathUtils.degToRad(100)
  camera.rotation.z = THREE.MathUtils.degToRad(0)

  const renderer = new THREE.WebGLRenderer({ alpha: true })
  // Set up the renderer
  renderer.setSize(threeContainer.value.clientWidth, threeContainer.value.clientHeight)
  // renderer.setClearColor(0xFFFFFF, 0)

  // Append the renderer to the container element
  if (threeContainer.value)
    threeContainer.value.appendChild(renderer.domElement)

  composer = new EffectComposer(renderer)
  composer.setSize(threeContainer.value.clientWidth, threeContainer.value.clientHeight)
  const renderPass = new RenderPass(scene, camera)
  composer.addPass(renderPass)

  const filmPass = new FilmPass(
    0.3, // noise intensity
    false,
  )
  composer.addPass(filmPass)

  // Add OrbitControls for easy camera movement
  // const controls = new OrbitControls(camera, renderer.domElement)
  // controls.enableDamping = true

  // Create 10 planes and add them to the scene
  const planeGeometry = new THREE.PlaneGeometry(50, 40)

  const opacity = 0.1 // Exponential decrease in opacity
  for (let i = 0; i < numberOfPlanes; i++) {
    const planeMaterial = new THREE.MeshBasicMaterial({
      color: new THREE.Color(`hsl(330, 50%, 50%)`), // Pink color
      transparent: true,
      depthWrite: false,
      opacity,
      side: THREE.DoubleSide,
      alphaTest: 0.01,
    })

    const plane = new THREE.Mesh(planeGeometry, planeMaterial)
    plane.position.set(0, 0, -i * gap) // Staggered z positions for depth effect
    planes.push(plane)
    scene.add(plane)
  }

  // Animation loop
  const animate = () => {
    requestAnimationFrame(animate)

    // Rotate the planes for a more dynamic effect
    planes.forEach((plane) => {
      plane.position.z += gap / numberOfPlanes * 0.5 // Move forward

      if (plane.position.z > 1) {
        plane.position.set(0, 0, -planes.length * gap) // Move to the back
        plane.material.color.setHSL(330, 100, 0.5)
      }

      if (plane.position.z > -50) {
        const hsl = {}
        const theHsl = plane.material.color.getHSL(hsl)
        const distance = Math.abs(plane.position.z + 50) // Calculate distance from camera
        const saturation = theHsl.s + distance * 0.001
        const lightness = theHsl.l + distance * 0.001
        plane.material.color.setHSL(330, saturation, lightness)
      }

      if (plane.position.z > -5)
        plane.material.opacity -= 0.005 // Decrease opacity

      else if (plane.material.opacity < 0.1)
        plane.material.opacity += 0.002 // Increase opacity
    })

    if (composer && scene)

      composer.render()
  }

  animate()
}

onMounted(() => {
  initThreeJS()
})

onBeforeUnmount(() => {
  for (let i = 0; i < numberOfPlanes; i++) {
    scene.remove(planes[i])
  }
})
</script>

<template>
  <div class="relative flex justify-center bg-neutral-950">
    <div ref="threeContainer" class="aspect-video h-[500px] overflow-hidden" />
  </div>
</template>
